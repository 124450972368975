<template lang="pug">
	div(:class="{'has-logo':showLogo}")
		logo(v-if='showLogo', :collapse='isCollapse')
		el-scrollbar(wrap-class='scrollbar-wrapper text-left')
			el-menu(:default-active='activeMenu', :collapse='isCollapse', :background-color='variables.menuBg', :text-color='variables.menuText', :unique-opened='false', :active-text-color='variables.menuActiveText', :collapse-transition='false', mode='vertical')
				div(v-for="route of routes" v-if="route.permit")
					el-submenu.tttt(v-if="!!route.children" index='1')
						template(slot='title')
							i.el-icon-location
							span Navigator One
					app-link(:to="route.path")
						el-menu-item(:index="route.path")
							i(:class="`el-icon-${route.icon}`")
							//menu-item(:title="route.label" :icon="route.icon" @click="$router.push(route.path)")
							span(slot='title') {{route.label}}

					// <sidebar-item v-for="route in routes" :key="route.path" :item="route" :base-path="route.path" />
					// <sidebar-item v-for="route in routes" :key="route.path" :item="route" :base-path="route.path" />

</template>

<script>
import { mapGetters } from 'vuex'
import Logo from './Logo'
import AppLink from './Link'
import variables from '@/style/variables.scss'
import menuItem from './Item'

export default {
  components: { menuItem, Logo, AppLink },
	data() {
  	return {
  		routes: [
				{
					label: 'Dashboard',
					icon: 's-home',
					path: '/dashboard',
					permit: this.permit(4)
				},
				{
					label: 'Users',
					icon: 'user-solid',
					path: '/user/list',
					permit: this.permit(1)
				},
				{
					label: 'Leads',
					icon: 's-finance',
					path: '/lead/list',
					permit: this.permit(3)
				},
				{
					label: 'Groups',
					icon: 'guide',
					path: '/group/list',
					permit: this.permit(3)
				},
				{
					label: 'Reports',
					icon: 's-order',
					path: '/report/list',
					permit: this.permit(0)  // @todo
				},
				{
					label: 'Settings',
					icon: 's-tools',
					path: '/settings/list',
					permit: this.permit(1)  // @todo
				},
			]
		}
	},
	methods: {
		permit(minLvl) {
			return this.$store.getters.rid <= minLvl
		},
		permitExact(lvl) {
			return this.$store.getters.rid == lvl
		},
	},
  computed: {
    ...mapGetters([
      'sidebar'
    ]),
    // routes() {
    //   return this.$router.options.routes
    // },
    activeMenu() {
      const route = this.$route
      const { meta, path } = route
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },
    showLogo() {
    	return true;
      // return this.$store.state.settings.sidebarLogo;
    },
    variables() {
      return variables
    },
    isCollapse() {
      return !this.sidebar.opened
    }
  }
}
</script>
