import {httpClient, removeToken, setToken} from "../../utils";
import Cookies from "js-cookie";
import {Login} from "@/api/auth";

const state = {
    token: Cookies.get('token'),
    name: localStorage.getItem('name'),
    uid: localStorage.getItem('uid'),
    rid: localStorage.getItem('rid')
}
const mutations = {
    SET_TOKEN: (state, token)  => {
        state.token = token;
    },
    SET_NAME: (state, name) => {
        state.name = name;
    },
    SET_UID: (state, uid) => {
        state.uid = uid;
    },
    SET_RID: (state, rid) => {
        state.rid = rid;
    }
}
const actions = {
    refresh({commit}, token) {
        commit('SET_TOKEN', token);
        setToken(token);
        // location.reload()
        httpClient.defaults.headers.Authorization = `Bearer ` + token
    },
    async login({commit}, data) {
        try {
            let result = await Login(data.username, data.password);
            if(!result) throw new Error('Could not login. Error:' + e.message)
            commit('SET_TOKEN', result.token);
            commit('SET_NAME', result.u.name);
            commit('SET_UID', result.u.id);
            commit('SET_RID', result.u.role);
            setToken(result.token)
            localStorage.setItem('name', result.u.name);
            localStorage.setItem('uid', result.u.id);
            localStorage.setItem('rid', result.u.role);
        } catch (e) {
            throw new Error('Could not login. Error:' + e.message)
        }
    },
    logout({commit}) {
        commit('SET_TOKEN', null);
        commit('SET_NAME', null);
        commit('SET_UID', null);
        commit('SET_RID', null);
        removeToken();
        localStorage.removeItem('name');
        localStorage.removeItem('uid');
        localStorage.removeItem('rid');
    }
}

const user = {
    namespaced: true,
    state,
    mutations,
    actions
};

export { user }
