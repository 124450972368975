import validator from "validator";
import {CheckExists} from "@/api/user";
import {call} from "runjs/lib/script";

export function isExternal(path) {
    return /^(https?:|mailto:|tel:)/.test(path)
}

export const validatePass = (rule, value, callback) => {
    let regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/
    if(value === '') callback();
    else if (value !== '' && !regex.test(value)) callback(new Error('Illegal Password'));
    else callback();
};

export const validatePassMandatory = (rule, value, callback) => {
    let regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/
    if(value === '') callback(new Error('Empty Password'));
    else if (value !== '' && !regex.test(value)) callback(new Error('Illegal Password'));
    else callback();
};

export const validateTelegramTextShort = (rule, value, callback) => {
    if(value === '') callback(new Error(`Field cannot be empty`));
    if(value.length < 2 || value.length > 256) callback(new Error('Must be between 2 and 256 characters'));
    else callback();
}

export const validateTelegramText = (rule, value, callback) => {
    if(value === '') callback(new Error(`Field cannot be empty`));
    if(value.length < 2 || value.length > 1024) callback(new Error('Must be between 2 and 1024 characters'));
    else callback();
}

export const validateTelegramTextLong = (rule, value, callback) => {
    if(value === '') callback(new Error(`Field cannot be empty`));
    if(value.length < 2 || value.length > 4096) callback(new Error('Must be between 2 and 4096 characters'));
    else callback();
}

export const validateTelegramChannel = (rule, value, callback) => {
    if(value === '') callback();
    if(Number.isNaN(+value)) callback(new Error(`Must be a digit`))
    if(value.indexOf('-100') === -1) callback(new Error(`A Telegram channel must start with "-100"`))
    else callback();
}

export const validateAlphaNumeric = (rule, value, callback) => {
    if(value === '') callback(new Error(`Field cannot be empty`));
    if(!validator.isAscii(value)) callback(new Error('Must be alphanumeric'));
    else callback();
}

export const validateEmail = (rule, value, callback) => {
    console.log(validator.isEmail(value))
    if(value === '') callback(new Error(`Field cannot be empty`));
    if(!validator.isEmail(value)) callback(new Error('Wrong email format'));
    else callback();
}

export const validateEntityName = (rule, value, callback) => {
    if(value === '') callback(new Error(`Field cannot be empty`));
    if(value.length < 2 || value.length > 64) callback(new Error('Must be between 2 and 64 characters'));
    else callback();
}

export const validateUsernameExists = (rule, value, callback) => {
    if(value === '') callback(new Error(`Field cannot be empty`));
    if(!validator.isAscii(value)) callback(new Error('Must be alphanumeric'));
    else {
        CheckExists(value, 'username')
            .then(res => !res?callback():callback(new Error('User already exists')))
            .catch(e => callback(new Error(e.message)))
    }
}

export const validateEmailExists = (rule, value, callback) => {
    if(value === '') callback(new Error(`Field cannot be empty`));
    if(!validator.isEmail(value)) callback(new Error('Wrong email format'));
    else {
        CheckExists(value, 'email')
            .then(res => !res?callback():callback(new Error('Email already exists')))
            .catch(e => callback(new Error(e.message)))
    }
}
