<template lang="pug">
	.d-inline()
		//el-dropdown.international(trigger='click', @command='handleSetLanguage')
			div(v-bind:class="{'chooseable':!className, [className]: className}")
				//label.pr-1(v-bind:class="{'chooseable':!className, [className]: className}") {{ $t("LANG") }}
				svg-icon(class-name='international-icon', icon-class='language')
			el-dropdown-menu(slot='dropdown')
				el-dropdown-item(:disabled="language==='en'", command='en')
					| English
				el-dropdown-item(:disabled="language==='ru'", command='ru')
					| Русский
				el-dropdown-item(:disabled="language==='fr'", command='fr')
					| Français
				el-dropdown-item(:disabled="language==='ua'", command='ua')
					| Українська
				el-dropdown-item(:disabled="language==='de'", command='de')
					| Dutch

</template>

<style lang="scss">
	.international-icon {
		width: 1.3em !important;
		height: 1.3em !important;
	}

	.chooseable {

	}
</style>

<script>
    export default {
        props: {
            className: {
                type: String,
            },
				},
        computed: {
            // language() {
            //     this.$i18n.locale = this.$store.state.app.language;
            //     return this.$store.state.app.language
            // }
        },
        methods: {
            /*handleSetLanguage(lang) {
                console.log(lang);
                this.$i18n.locale = lang;
                this.$store.dispatch('app/setLanguage', lang);
                this.$message({
                    message: 'Switch Language Success',
                    type: 'success'
                })
            }*/
        }
    }
</script>
