<template lang="pug">
	section.app-main
		transition(name='fade-transform', mode='out-in')
			router-view(:key='key')

</template>

<script>
export default {
  name: 'AppMain',
  computed: {
    key() {
      return this.$route.path
    }
  }
}
</script>

<style scoped>
.app-main {
  /*50 = navbar  */
	background-color: rgb(240, 242, 245);
  min-height: calc(100vh - 50px);
  width: 100%;
  position: relative;
  overflow: hidden;
	background-color: rgb(240, 242, 245);
	/*background-image: url(~@/assets/img/dotted-arrow-dark.png);*/
}
.fixed-header+.app-main {
  /*padding-top: 50px;*/
}
</style>

<style lang="scss">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
  .fixed-header {
    padding-right: 15px;
  }
}
</style>
