import {httpClient} from "@/utils";

export const hc = function (filters) {
    return httpClient.get('settings/hc', )
}

export const GetSettings = function() {
    return httpClient.get('settings/form')
}

export const UpdateSettingsList = function (list) {
    return httpClient.put('settings/form', { list });
}
