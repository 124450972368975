import moment from 'moment';

export default  {
    install(Vue) {
        Vue.filter('upper', function (value) {
            if (!value) return ''
            value = value.toString()
            return value.toUpperCase()
        });
        Vue.filter('formatDateTime', function (value) {
            if (!value) return ''
            value = value.toString()
            return moment(value).format('YYYY-MM-DD HH:mm:ss')
        });
        Vue.filter('formatDateTimeShort', function (value) {
            if (!value) return ''
            value = value.toString()
            return moment(value).format('YYYY-MM-DD HH:mm')
        });
        Vue.filter('formatDate', function (value) {
            if (!value) return ''
            value = value.toString()
            return moment(value).format('YYYY-MM-DD')
        });
        Vue.filter('truncate', function (value, length) {
            if (value.length < length) {
                return value
            }

            length = length - 3
            return value.substring(0, length) + '...'
        });
        Vue.filter('percentage', function (value, decimals) {
            if (!value) {
                value = 0
            }

            if (!decimals) {
                decimals = 0
            }

            value = value * 100
            value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals)
            value = value + '%'
            return value
        });
        Vue.filter('round', function (value, decimals) {
            if (!value) {
                value = 0
            }

            if (!decimals) {
                decimals = 0
            }

            value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals)
            return value
        });
    }
}
