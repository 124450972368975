import Vue from 'vue'
import Router from 'vue-router'
import Layout from '@/layout'
Vue.use(Router);
const $routes = [
    {
        path: '/',
        name: 'login',
        component: () => import('../views/Login/index.vue')
    },
    {
       path: '/goto',
       name: 'go-to',
       component: () => import('../components/goto')
    },
    {
        path: '/404',
        component: () => import('@/views/404'),
        hidden: true
    },
    {
        path: '/app',
        component: Layout,
        children: [
            {
                path: '',
                redirect: '/dashboard',
            },
            {
                path: '/dashboard',
                name: 'dashboard',
                component: () => import('../views/Dashboard/index.vue'),
            }
        ]
    },
    /*{
        path: '/product',
        component: Layout,
        redirect: '/product/list',
        meta: { title: 'Product' },
        children: [
            {
                path: 'list',
                name: 'List',
                component: () => import('@/views/Products/list'),
                meta: { title: 'List' }
            },
            {
                path: 'edit/:id',
                name: 'Edit',
                component: () => import('@/views/Products/form'),
                meta: { title: 'Edit' }
            },
            {
                path: 'create',
                name: 'Create',
                component: () => import('@/views/Products/form'),
                meta: { title: 'Create' }
            }
        ]
    },*/


    // Report
    {
        path: '/report',
        component: Layout,
        redirect: '/report/list',
        meta: { title: 'Report' },
        children: [
            {
                path: 'list',
                name: 'List',
                component: () => import('@/views/Report/list'),
                meta: { title: 'List' }
            },
        ]
    },

    // settings
    {
        path: '/settings',
        component: Layout,
        redirect: '/settings/list',
        meta: { title: 'Settings' },
        children: [
            {
                path: 'list',
                name: 'List',
                component: () => import('@/views/Settings/list'),
                meta: { title: 'List' }
            },
        ]
    },

    // User
    {
        path: '/user',
        component: Layout,
        redirect: '/user/list',
        meta: { title: 'User', allowed: [1] },
        children: [
            {
                path: 'list',
                name: 'List',
                component: () => import('@/views/User/list'),
                meta: { title: 'List', allowed: [1] }
            },
            {
                path: 'edit/:id',
                name: 'Edit',
                component: () => import('@/views/User/form'),
                meta: { title: 'Edit', allowed: [1] }
            },
            {
                path: 'create',
                name: 'Create',
                component: () => import('@/views/User/form'),
                meta: { title: 'Create', allowed: [1] }
            }
        ]
    },

    // Lead
    {
        path: '/lead',
        component: Layout,
        redirect: '/lead/list',
        meta: { title: 'Lead', allowed: [1,2,3] },
        children: [
            {
                path: 'list',
                name: 'List',
                component: () => import('@/views/Lead/list'),
                meta: { title: 'List', allowed: [1,2,3] }
            },
            {
                path: 'edit/:id',
                name: 'Edit',
                component: () => import('@/views/Lead/form'),
                meta: { title: 'Edit', allowed: [1,2] }
            },
            {
                path: 'create',
                name: 'Create',
                component: () => import('@/views/Lead/form'),
                meta: { title: 'Create', allowed: [1,2] }
            }
        ]
    },

    // Group
    {
        path: '/group',
        component: Layout,
        redirect: '/group/list',
        meta: { title: 'Group', allowed: [1,2,3] },
        children: [
            {
                path: 'list',
                name: 'List',
                component: () => import('@/views/Group/list'),
                meta: { title: 'List', allowed: [1,2,3] }
            },
            {
                path: 'edit/:id',
                name: 'Edit',
                component: () => import('@/views/Group/form'),
                meta: { title: 'Edit', allowed: [1,2] }
            },
            {
                path: 'create',
                name: 'Create',
                component: () => import('@/views/Group/form'),
                meta: { title: 'Create', allowed: [1,2] }
            }
        ]
    },

]

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: $routes
});
