import Cookies from 'js-cookie'
import axios from 'axios'
import store from '@/store'
import { Message, Notification } from 'element-ui';

const { VUE_APP_BASE_API, VUE_APP_REQUEST_TIMEOUT } = process.env;

const httpClient = axios.create({
    baseURL: VUE_APP_BASE_API,
    timeout: VUE_APP_REQUEST_TIMEOUT
});

const requestHandler = (config) => {
    if(!store.getters.token) config.headers['Authorization'] = `Bearer ${Cookies.get('token')}`;
    return config;
}

const responseHandler = (response) => {
    const { data, err, errDesc, msg, msg_type } = response.data;
    if(msg) Notification[msg_type || 'info']({title: 'API Response', message: msg});
    if(err) throw new Error(errDesc);
    return data;
}

const requestError = (err) => {
    console.log(err);
    Notification.error({title: 'API Error', message: '[httpClient error interceptor] Request Error: ' + err.data + ', Status: ' + err.stat})
    throw err;
}

const responseError = (err) => {
    console.error('[httpClient error interceptor] error', err.response);
    if(err.response.status === 401 && err.response.data === 'Auth failed' || err.response.data === 'Not existing') {
        store.dispatch('user/logout').then(() => window.location.href = '/');
    } else if(err.response.status >= 400 && err.response.status <= 499){
        // Message.error('[httpClient error interceptor] Response Error: ' + (err.response.data ? err.response.data.msg || err.response.data.err : err.message))
        Notification.error({title: 'HTTP Request Error', message: err.response.data.msg || err.response.data.err || err.response.data})
    } else if(err.response.status >= 500 && err.response.status <= 599){
        // Message.error('[httpClient error interceptor] Response Error: ' + (err.response.data ? err.response.data.msg || err.response.data.err : err.message))
        Notification.error({title: 'API Error', message: err.response.data.msg || err.response.data.err || err.response.data})
    }
    throw err;
}

httpClient.interceptors.request.use(requestHandler, requestError);
httpClient.interceptors.response.use(responseHandler, responseError);
export { httpClient };
