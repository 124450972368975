import {httpClient} from "@/utils";

export const GetList = function(limit, page, keyword, type) {
    return httpClient.get('user/list', { params: { limit, page, keyword, type } } );
}

export const GetEntity = function(uid) {
    return httpClient.get('user/' + uid);
}

export const CreateEntity = function(data) {
    return httpClient.post('user/', data)
}

export const UpdateEntity = function(uid, data) {
    return httpClient.put('user/' + uid, data);
}

export const DeleteEntity = function(uid) {
    return httpClient.delete('user/' + uid)
}

export const CheckExists = function(keyword, type) {
    return httpClient.get('user/exists', { params: {keyword, type} })
}
