<template lang="pug">
	#app
		router-view
		vue-progress-bar
</template>

<script>

import {getToken, hasToken} from "@/utils";
import {hc} from "@/api/settings";
import {Validate} from "@/api/auth";

export default {
  name: 'App',
  components: {

  },
	async beforeCreate() {
		const tokenExists = hasToken();
		console.log(tokenExists, this.$route);
		if (!tokenExists) {
			await this.$store.dispatch('user/logout');
		} else {
			let check = await Validate(await getToken());
			if(!check || check !== 'ok') {
				await this.$store.dispatch('user/logout');
				clearInterval(window.hc);
			}
			if (this.$route.name === 'login') this.$router.push({name: 'dashboard'}).then()
		}
	},
	created() {
  	if(this.$store.state.user.uid) {
			hc().then((res) => {
				if (res.token) this.$store.dispatch('user/refresh', res.token)
			});
			window.hc = setInterval(() => hc(), 6000)
		}
		this.$Progress.start();
		this.$router.beforeEach((to, from, next) => {
			if (to.meta.progress !== undefined) {
				let meta = to.meta.progress
				// parse meta tags
				this.$Progress.parseMeta(meta);
			}
			this.$Progress.start()
			//  continue to next page
			next()
		});
		this.$router.afterEach(() => {
			//  finish the progress bar
			this.$Progress.finish()
		})
	}
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 50px;
	@media only screen and (max-width: 560px) {
		margin-top: 0;
	}
}
</style>
