import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router';

Vue.config.productionTip = false
import '@/style/index.scss' // global css

import Element from 'element-ui';
import {Form} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/en'
Vue.use(Element, { size: 'small', zIndex: 3000, locale });
Vue.use(Form);
import('./interceptors/router.guard');
import('./interceptors/permission.guard');

// Debounce functionality
import vueDebounce from 'vue-debounce'
Vue.use(vueDebounce, {
  defaultTime: '500ms'
});

// Filters
import filters from './filters';
Vue.use(filters);

// Numeric filter
import vueNumeralFilterInstaller from 'vue-numeral-filter';
Vue.use(vueNumeralFilterInstaller);


// Vue Progress bar
import VueProgressBar from 'vue-progressbar'

// Overlay
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
Vue.use(Loading);

Vue.use(VueProgressBar, {
  color: '#30b6cf',
  failedColor: '#874b4b',
  height: '2px',
  thickness: '5px',
  transition: {
    speed: '0.2s',
    opacity: '0.6s',
    termination: 300
  },
  autoRevert: true,
  location: 'top',
  inverse: false
})

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faBars,
  faSearch,
  faPlus,
  faMinus,
    faList,
  faPencilAlt,
  faClone,
  faCamera, faPlane,
  faTrashAlt, faCheck, faTimes, faUserSecret,
  faCar, faShoppingBasket, faUserAlt, faUsers, faDollarSign
} from "@fortawesome/free-solid-svg-icons";
import {faUser} from "@fortawesome/free-regular-svg-icons";

library.add(faBars, faPlus, faMinus, faSearch, faPencilAlt, faClone, faCamera, faTrashAlt, faPlane,
    faUserAlt, faShoppingBasket, faDollarSign, faCar, faCheck, faTimes, faList, faUsers, faUserSecret);
Vue.component("font-awesome-icon", FontAwesomeIcon);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
