<template lang="pug">
	.navbar
		hamburger.hamburger-container(:is-active='sidebar.opened', @toggleClick='toggleSideBar')
		breadcrumb.breadcrumb-container
		.right-menu
			el-dropdown
				span.el-dropdown-linkpr-3.clickable Hi, {{ userName + ' ' }}
					i.el-icon-arrow-down.el-icon--right
				el-dropdown-menu(slot='dropdown')
					router-link(to='/dashboard')
						el-dropdown-item Dashboard
					el-dropdown-item(divided='')
						span(@click="logout") Logout


</template>

<script>
    import Breadcrumb from '@/components/Breadcrumb'
    import Hamburger from '@/components/Hamburger'
    import LangSelect from '@/components/LangSelect'
    export default {
        components: {
            Breadcrumb,
            Hamburger,
        },
        computed: {
            userName() {
                return this.$store.getters.name;
            },
					sidebar() {
            	return this.$store.state.app.sidebar
					}
        },
        methods: {
            toggleSideBar() {
                this.$store.dispatch('app/toggleSideBar')
            },
            async logout() {
                await this.$store.dispatch('user/logout');
                window.location.href = (`/`)
            },
        },
    }
</script>

<style lang="scss" scoped>
	.navbar {
		/*height: 50px;*/
		overflow: hidden;
		position: relative;
		background: #fff;
		box-shadow: 0 1px 4px rgba(0, 21, 41, .08);

		.hamburger-container {
			line-height: 46px;
			height: 100%;
			float: left;
			cursor: pointer;
			transition: background .3s;
			-webkit-tap-highlight-color: transparent;

			&:hover {
				background: rgba(0, 0, 0, .025)
			}
		}

		.breadcrumb-container {
			float: left;
		}

		.right-menu {
			float: right;
			height: 100%;
			line-height: 50px;

			@media only screen and (max-width: 767px) {
				display: flex;
				justify-content: space-between;
				//width: 100%;
			}

			&:focus {
				outline: none;
			}

			.right-menu-item {
				display: inline-block;
				padding: 0 8px;
				height: 100%;
				font-size: 18px;
				color: #5a5e66;
				vertical-align: text-bottom;

				&.hover-effect {
					cursor: pointer;
					transition: background .3s;

					&:hover {
						background: rgba(0, 0, 0, .025)
					}
				}
			}

			.avatar-container {
				margin-right: 30px;

				.avatar-wrapper {
					margin-top: 5px;
					position: relative;

					.user-avatar {
						cursor: pointer;
						width: 40px;
						height: 40px;
						border-radius: 10px;
					}

					.el-icon-caret-bottom {
						cursor: pointer;
						position: absolute;
						right: -20px;
						top: 25px;
						font-size: 12px;
					}
				}
			}
		}
	}
</style>
