import Vue from 'vue'
import Vuex from 'vuex'
import { getters } from "./getters";
import {user, app} from "./modules";

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: { app, user },
    getters
});

export default store;
